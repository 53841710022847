@import "../../util/variables";

.receivingChatMessage-container {
    margin-bottom: 10px;
    max-width: 70%;
    width: auto;
    display: flex;
    flex-direction: row;
    position: relative;
}

.receivingChatMessage-chat-image {
    width: 30px !important;
    height: 30px !important;
    margin-right: 20px;

}


.receivingChatMessage-speech-bubble {
    position: relative;
    background: $container_background_color;
    border-radius: .4em;
    padding: 10px;

}

.receivingChatMessage-speech-bubble:after {
    content: '';
    position: absolute;
    left: 1px;
    top: 20px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-right-color: $container_background_color;
    border-left: 0;
    border-top: 0;
    margin-top: -5.5px;
    margin-left: -11px;
}

.receivingChatMessage-TextField {
    color: $typography_text_white;
}