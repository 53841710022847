@import "util/variables";

body {
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

#root,
#frame {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $background_color;
}

.r2r-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.r2r-timepicker {
    width: auto;
}

.r2r-bottomNavigation {
    position: fixed;
    bottom: 0px;
    width: 100%;
    position: sticky;
    z-index: 1000;

    a {
        min-width: 36px !important;
    }
}

.r2r-appbar-iconbutton {
    margin-right: 20px;
}

.r2r-customdialog-appbar {
    position: relative !important;
}


.r2r-container-flex-grow {
    flex-grow: 1;
}

.r2r-pointer{
    cursor: pointer;
}

.r2r-container-header {
    width: 90%;
    margin: 0 auto 0 auto;
    padding: 5%;
}

.r2r-container-content {
    width: 95%;
    margin: 20px auto 0 auto !important;
    max-width: 1300px;
}

.r2r-container-header-margin {
    margin: 10px 0;
}

.r2r-contentContainer-element-margin {
    margin-bottom: 20px !important;
}

.r2r-content-title {
    margin-bottom: 12px !important;
    text-align: center;
}

.r2r-dashboardpanel-title {
    margin-bottom: 12px !important;
    // text-align: center;
}

.r2r-container-backgroundcolor {
    background-color: $container_background_color;
}

.r2r-container-content-flex-row {
    display: flex;
    flex-direction: row;
}

.r2r-container-content-flex-column {
    display: flex;
    flex-direction: column;
}

.r2r-container-center-align {
    justify-content: center;
    align-items: center;
}

.app-btn-secondary {
    width: 100%;
    background-color: $secondary !important;
    margin-top: 20px !important;
}

.r2r-upload-label {
    display: none;
}

.app-login-progress {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.r2r-typography-bold {
    font-weight: bold !important;
}



.app-button {
    width: 100%;
    font-weight: bold !important;
}

.app-fab {
    margin: 2px;
    top: auto;
    right: 7px;
    bottom: 61px;
    left: auto;
    position: fixed;
    z-index: 1000;
}

.app-messageEmpty-container {
    height: 100% !important;
}

.r2r-display-flex {
    display: flex;
}

.r2r-justify-spaceBetween {
    justify-content: space-between;
}

.r2r-justify-center {
    justify-content: center;
}

.r2r-color-secondary {
    color: $secondary !important;
}

.r2r-margin-top-bottom {
    margin: 10px 0;
}

.app-progressContainer {
    background-color: #FFFFFF;
    opacity: 1;
    position: fixed;
    /* Sit on top of the page content */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
}

.r2r-dashboard-card-margin {
    margin: 0 10px 10px 10px;
}