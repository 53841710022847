@import "../../util/_variables.scss";

.rideView-flex-date {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.rideView-flex-date-left {
    flex-grow: 50;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 200%;
}

.rideView-flex-date-right {
    flex-grow: 50;
    justify-content: flex-end;
    font-size: 100%;
    align-items: center;
}

.rideView-flex-driver {
    flex-direction: column;
}

.rideView-flex-driver-content {
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.rideView-margin-bottom {
    margin-bottom: 20px;
}

.rideView-flex-driver-left {
    display: flex;
    flex-direction: column;
    min-width: 65%;
}

.rideView-flex-driver-left-rating {
    align-items: center;
}

.rideView-flex-driver-left-seats {
    margin-top: 0px;
    align-items: center;
}

.rideView-flex-driver-left-phone {
    margin-top: 0px;
    align-items: center;
}


.rideView-flex-driver-left-icon {
    margin-right: 8px;
}


.rideView-avatar {
    width: 70px !important;
    height: 70px !important;
}


.rideView-flex-description {
    margin: 10px 0px 0px 0px;
    text-align: justify;
}


.rideView-attend-button {
    margin: 20px 0px;
}

.rideView-padding-content {
    padding-top: 20px;
}

.rideView-message-box {
    width: 100%;
    border-color: $secondary;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
}

.rideView-message {
    width: 100%;
    text-align: center !important;
    margin: 20px !important;
}

.rideView-flex-ride-info {
    height: 60px;
}

.rideView-flex-ride-info-left {
    min-width: 80px;
    align-self: flex-start;
}

.rideView-flex-ride-info-mid {
    align-items: center;
    width: auto;
    margin: 0px 0px 0px 20px;
}

.rideView-flex-ride-info-right {
    margin-left: 5px;
    align-self: flex-start;
}

.rideView-status {
    padding: 10px;
    border-radius: 8px;
}