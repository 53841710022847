.attendee-flex-outer {
    margin: 0px 0px 12px 0px;
    flex-direction: row;
    align-items: center;
}

.attendee-flex-pic {
    margin-right: 12px;
}

.attendee-space-between {
    justify-content: space-between;
}