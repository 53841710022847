@import "../../util/variables";

.environmentalPanel-alignContent-center {
    align-content: center;
}

.environmentalPanel-cardcontent-margin {
    margin: 0 0 8px 0;
}

.environmentalPanel-cardcontent-font {
    margin: 5px 0 5px 0;
}

.environmentalPanel-cardaction-link {
    justify-content: flex-end;
}

.environmentalPanel-eco-icon {
    height: 50px !important;
    width: 50px !important;
    color: $eco-green;
}