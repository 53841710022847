.noRide-card-margin {
    margin: 0 10px 10px 10px;
    width: calc(100% - 20px) !important;
}

.noRide-card {
    padding-bottom: 16px !important;
}

.noRide-flex-outer {
    flex-direction: row;
    align-items: center;
    align-content: center;
}