@import "../../util/variables";

.vehicleSelectorComp-vehicleImage {
    margin-right: 5px;
    margin-left: 5px;
    // width: 90px;
    // height: auto;
    width: 150px;
    height: 100px;
    object-fit: cover;
}

.vehicleSelectorComp-horizontalContainer {
    overflow-x: auto
}

.vehicleSelectorComp-horizontalContainer::-webkit-scrollbar {
    display: none;
}

.vehicleSelectorComp-vehicleContainer {
    padding: 10px;
    width: auto;
    height: auto;
}

.vehicleSelectorComp-carSelected {
    border-color: $secondary;
    border-style: solid;
    border-radius: 10px;
}

.vehicleSelectorComp-vehicleTitel {
    width: auto !important;
}