.showResults-headerPanel {
    justify-content: space-around;
    position: relative;
}

.showResults-arrow {
    margin: auto 0;
}

.showResults-time {
    margin: 10px 0;
    text-align: center;
}

.showResults-content-background {
    padding: 20px 0 0 0;
}

.showResultsFormControl {
    width: 100%;
    margin: 20px auto !important;
}

.showResults-remove-padding-left {
    padding-left: 0 !important;
}

.showResults-expand-button {
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-end;
}

.showResults-align-vertical {
    vertical-align: middle;
    margin-top: 25% !important;
    justify-content: center;
}