.appRating-starBox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 10px 0;
}

.appRating-checkbox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0 0 0;
}