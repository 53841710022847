@import "../../util/variables";

.parkingSpotPanel-progressBar-container {
    padding: 20px 20px 0 20px;
}

.parkingSpotPanel-progressBar {
    padding: 10px 0;
}

.parkingSpotPanel-cardcontent-font {
    margin: 5px 0;
}

.parkingSpotPanel-warning-icon {
    margin-right: 10px;
    color: $warning-red;
}