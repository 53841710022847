.dashboard-container {
    padding: 0 0 20px 0;
}

.dashboard-header-text {
    color: white
}

.dashboard-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dashboard-dialog {
    min-width: 400px;
}