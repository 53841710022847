@import "../../util/variables";

.searchRide-radioGroup {
    justify-content: space-between !important;
    color: $typography_text_white !important;
}

.searchRide-radioLabel {
    color: $typography_text_white !important;
}

.test {
    border-color: red;
}