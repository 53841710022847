@import "../../util/variables";

.viewRating-flex-upper {
    flex-direction: row;
    align-items: center;
    align-content: center;
    border-bottom-style: solid;
    border-bottom-width: 0px;
}

.viewRating-flex-upper-right {
    flex-direction: column;
    color: $typography_text_white;
    align-self: center;
}

.viewRating-flex-upper-right-top {
    font-weight: bold;
    font-size: 250%;
}

.viewRating-flex-upper-right-bottom {
    font-size: 120%;
}

.viewRating-align-vertical {
    vertical-align: middle;
    margin-top: 30% !important;
}