.error404_container {
    width: 95%;
    height: inherit;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    align-content: center;

    .error404_description {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .error404_image {
        margin-top: 25px;
        margin-bottom: 25px;
    }

}