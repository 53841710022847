.historyElement-content {
    margin: 5px 0px;
    flex-direction: column;
}

.historyElement-header-row {
    flex-direction: row;
    justify-content: space-between;
}

.historyElement-magrin {
    margin: 5px 0 !important;
}

.historyElement-width {
    width: 100%
}

.historyelement-status {
    display: flex;
    align-items: center;
}