$container_background_color: #727272;
$primary: #474747;
$secondary: #ffcc00;
// $secondary:#f0d23c;
$eco-green: green;
$warning-red: red;
$background_color: #EEEEEE;
$primary_light: #bebebe;

$scoreing-gold: gold;
$scoreing-silver: silver;
$scoreing-brown: brown;

$fatal_error: #f44336;

$typography_text_white: #fff;