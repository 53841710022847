@import "../../util/variables";

.chat-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
}


.chat-area {
    margin: 0px 10px 0px 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;
    flex: 1 1 0;
    position: relative;

}

.chat-message-container {
    order: 3;
    position: relative;
    left: 0px;
    bottom: 0px;
    display: flex;
    align-self: flex-end;
    width: 90%;
    margin: 0 auto 0 auto;
    padding: 10px 5% 10px 5%;
    height: auto;
    background: $primary;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.chat-message-box {
    width: 100% !important;
}

.chat-send-button {
    margin-left: 10px !important;
}

.chat-label {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;

}