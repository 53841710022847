.attendedRide-flex-outer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.attendedRide-flex-pic {
    margin-right: 10px;
}

.attendedRide-flex-right-name {
    font-weight: bold;

}

.attendedRide_wrapper {
    flex-direction: row;
    align-items: center;
    align-content: center;
    display: flex;
}

.attendedRide-rideTime-div {
    text-align: right !important;
    width: 100%;
    font-size: 90%;

}

.attendedRide-flex-right {
    width: 100%;
    overflow: hidden;
}

.attendedRide-flex-right-text {
    overflow: hidden;
    width: 100%;
    min-width: 160px;
    font-size: 90%;
}