.userRide-flex-ride {
    flex-direction: row;
    align-items: center;
}

.userRide-flex-content {
    flex-direction: column;
}

.userRide-flex-ride-info {
    margin: 0px 10px 0px 10px;
    height: 60px;
}

.userRide-flex-ride-info-left {
    min-width: 70px;
    align-self: center;
}

.userRide-flex-ride-info-mid {
    width: 40px;
}

.userRide-flex-ride-info-right {
    align-self: center;
}