@import "../../util/variables";

.myProfil-label {
    display: flex;
    margin: 10px 0;

}

.myProfil-icon {
    margin: 0 10px 0 0;
}

.myProfil-faIcon {
    margin: 0 10px 0 0;
    width: 24px !important;
    height: 24px !important;
    color: $secondary;
}

.profil-identityInformations {
    padding: 0 10px 10px 10px;
}