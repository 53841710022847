@import "../../../util/variables";

.avatar {
    text-align: center;
    width: 100px !important;
    height: 100px !important;
}

.pah-inner-container {
    // width: 100%;
    width: 95%;
    margin: 0 auto;
    display: flex;
    // margin: 5%;
    flex-direction: row;
    align-items: center;
}

.pah-header {
    width: 100%;
    max-height: 250px;
    min-height: 150px;
}

.avatar-text {
    margin: 0 10px;
    border: solid 2px;
    border-color: $secondary;
}

.avatar-noText {
    margin: 0 auto;
}

.header-text {
    color: white
}