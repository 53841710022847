.editRide-container-content-flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.editRide-title-center {
    text-align: center;
}

.editRide-title {
    width: 100%;
    text-align: center !important;
    color: #ffff !important;
    font-weight: bold !important;
}

.editRide-center-between {
    justify-content: space-between;
}

.editRide-flex-grow {
    flex-grow: 1;

}

.editRide-padding-right {
    width: 90%;

}

.editRide-label-switch-margin {
    margin-right: 10px !important;

}

.editRide-title-routine {
    margin-top: 20px !important;

}