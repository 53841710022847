@import "../../util/variables";

.giveRatingDialog-iconButton {
    padding: 0 !important;
}

.giveRatingDialog-starBox {
    flex-direction: row;
    width: 100%;
}

.giveRatingDialog-starBoxInvalid {
    flex-direction: row;
    width: 100%;
    border-color: $fatal_error;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
}

.giveRatingDialog-commentBox {
    width: 100% !important;
    margin-top: 20px !important;
}