@import "../../util/variables";

.scoringPanel-entry {
    margin: 0 0 10px 0
}

.scoringPanel-iconcontainer {
    align-items: center;
}

.scoringPanel-iconsize {
    font-size: 25px !important;
}

.scoringPanel-icongold {
    // color: $scoreing-gold
    border: 3px solid $scoreing-gold
}

.scoringPanel-iconsilver {
    // color: $scoreing-silver
    border: 3px solid $scoreing-silver
}

.scoringPanel-iconbrown {
    // color: $scoreing-brown
    border: 3px solid $scoreing-brown
}

.scoringPanel {
    flex-direction: column;
}

.scoringPanel-score-text {
    width: 200px;
    text-align: end;
    font-weight: normal;
}