.feedback-select {
    opacity: 1;
    z-index: 5;
}

.feedback-commentBox {
    width: 100% !important;
    height: 40%;
    margin-top: 20px !important;
}

.feedback-card {
    width: 84% !important;
    margin: 5% !important;
    height: 90% !important;
    padding: 3% !important;
}

.feedback-button {
    margin: 1% !important;
    width: 98% !important;
}