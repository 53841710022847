.nextRideElement {
    width: 100%;
}

.nextRideElement-flex-pic {
    margin: 0 20px 0 0;
    align-self: center
}

.nextRideElement-header-row {
    flex-direction: row;
}

.nextRideElement-width {
    width: 100%
}