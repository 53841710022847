@import "../../util/variables";

.notification-card {
    margin: 0 10px 10px 10px;
    height: 100%;
}

.notification-unreadedMessage span {
    font-weight: bolder
}

.notification-unreadedMessage p {
    font-weight: bolder
}

.notificationOverview-text {
    height: inherit;
    align-items: center
}

.notificationOverview-title-date {
    color: $primary_light
}