.MuiAutocomplete-root fieldset {
    border-color: rgba(255, 255, 255, 1.00);
}

.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 1.00) !important;
}

.testAutocomplete-margin {
    margin-top: 20px !important;
    margin-left: 10px;
}

.testAutocomplete-width {
    width: 100%;

    div {
        color: white;
    }


    div label {
        &:focus {
            color: white;
        }

        color: white;
    }
}

.testAutocomplete-flex {
    display: flex;
}