.r2r-vehicleCard {
    margin-bottom: 10px;
}

.r2r-vehicleCard-car-info {
    flex-direction: column;
    margin-left: 40px;
    align-items: center;
}

.r2r-vehicleCard-car-info div:last-child {
    margin-bottom: 5px;
}

.r2r-vehicleCard-right-margin {
    margin: 5px 0 0 0;
}

.r2r-vehicleCard-image {
    height: auto;
    width: 100px;
}

.r2r-vehicleCard-image-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.r2r-vehicleCard-paddingBottom-0 {
    padding-bottom: 0px !important;
}