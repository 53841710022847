@import "../../util/variables";


.r2r-container-header r2r-display-flex r2r-container-backgroundcolor r2r-container-content-flex-column {
    height: 10% !important;
}

.createRide_loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.createRide-felxgrow {
    flex-grow: 1;
    margin: 0 5px;
}



.createRide_loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.createRide-container-content-flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.createRide-center-between {
    justify-content: space-around;
}

.createRide-flex-grow {
    flex-grow: 1;
}

.createRide-padding-right {
    width: 90%;
}

.createRide-label-switch-margin {
    margin-right: 10px !important;

}

.createRide-horizontalContainer {
    overflow-x: auto
}

.createRide-horizontalContainer::-webkit-scrollbar {
    display: none;
}