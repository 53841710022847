@import "../../util/variables";

.sendingChatMessage-box {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
}

.sendingChatMessage-container {
    max-width: 70%;
    width: auto;
    display: flex;
    flex-direction: row;
}

.sendingChatMessage-chat-image {
    width: 30px !important;
    height: 30px !important;
    margin-left: 20px;
}


.sendingChatMessage-speech-bubble {
    position: relative;
    background: $secondary;
    border-radius: .4em;
    padding: 10px;
    display: flex;
    flex-direction: column;

}

.sendingChatMessage-speech-bubble:after {
    content: '';
    position: absolute;
    right: 1px;
    top: 20px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-left-color: $secondary;
    border-right: 0;
    border-top: 0;
    margin-top: -5.5px;
    margin-right: -11px;
}

.sendingChatMessage-TextField {
    color: black;
}

.sendingChatMessage-time-date-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}