@import "../../util/variables";

.movingOffer-avatar {
    width: 40px !important;
    height: 40px !important;
}

.movingOffer-firstLine {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}


.movingOffer-icon {
    margin: 0 10px 0 0;
}

.movingOffer-name {
    align-items: center;
}

.movingOffer-name-content {
    line-height: 1 !important;
}

.movingOffer-margin-laterally {
    margin: 0 5px;
}

.movingOffer-savedEmission-icon {
    color: $eco-green;
}


.r2r-movingOffer-flex-content {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
}

.movingOffer-card {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}


.movingOffer-expansionPanelOuter {
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
    min-height: 0 !important;
}


.movingOffer-flex-ride-info-mid {
    align-items: center;
    width: auto;
    margin: 0px;
}

.movingOffer-flex-ride-info-right {
    margin-left: 5px;
    align-self: flex-start;

}

.movingOffer-flex-ride-info-left {
    font-weight: bold;
    min-width: 70px;
    align-self: flex-start;
}