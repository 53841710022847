@import "../../util/variables";

.chatChannel-image {
    width: 53px !important;
    height: 53px !important;
    margin-right: 16px
}

.chatChannel-text {
    line-height: 1.25 !important;
}

.chatChannel-container {
    display: flex;
    width: 90%;
    margin: 0 auto 0 auto;
    padding: 5%;
}

.chatChannel-container:hover {
    background-color: #f5f5f5;
}

.chatChannel-devider {
    width: 90%;
    margin: 0 auto 0 auto !important;
}

.chatChannel-space-between {
    justify-content: space-between;
}

.chatChannel-badge {
    display: flex;
    justify-content: center;
    background-color: $secondary;
    font-size: 0.75rem;
    border-radius: 10px;
    height: 20px;
    width: auto;
    min-width: 20px;
    box-sizing: border-box;
    align-items: center;
    font-weight: 500;
}