.addVehicleDialog-image {
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
}

.addVehicleDialog-input-container {
    flex-direction: row;
    align-items: flex-end;
}

.addVehicleDialog-plate-container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    div {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.addVehicleDialog-image-error {
    border: 2px solid #f44336 !important
}

.addVehicleDialog-button-upload {
    background-color: #727272;
    width: auto;
    height: auto;
    padding: 3px;
    border-radius: 2px;
}

.addVehicleDialog-container {
    position: relative;
}

.addVehicleDialog-img {
    opacity: 1;
    display: block;
    width: 100%;
    max-width: 600px;
    height: auto;
    width: 250px;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    border: 2px solid transparent;
}

.addVehicleDialog-prefixicon-textfield {
    height: 30px;
    width: 30px;
    margin: 0 8px 5px 0;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.addVehicleDialog-uploadtext {
    right: 0;
    top: 0;
    position: absolute;
    background-color: #ffcc00;
    color: white;
    font-size: 10px;
    padding: 10px 10px;
}