@import "../../util/variables";

.user-flex-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.user-image {
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
}

.user-input-fields-required {
    display: flex;
    margin-left: 20px;
    width: 100%;
}

.user-checkbox-error .MuiIconButton-label {
    color: $fatal_error;
}

.user-checkbox-text-error {
    color: $fatal_error;
}

.user-gender-error {
    color: $fatal_error !important;
}

.user-image-error {
    border: 2px solid $fatal_error !important;
}

.user-checkbox {
    margin: 20px 0 0 0;
    display: flex;
}

.user-img {
    opacity: 1;
    display: block;
    width: 100%;
    max-width: 600px;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    border: 2px solid transparent;
}

.user-flex-gender-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.user-flex-fields-icon {
    margin-bottom: 5px;
}

.user-flex-gender-icons-icon {
    margin: 0px 15px 0px 15px;
}

.user-flex-button {
    margin-top: 20px;
}

.user-iconSize {
    font-size: 40px;
    width: 40px !important;
    height: 40px !important;
}

.user-container {
    position: relative;
}

.user-uploadtext {
    right: 0;
    top: 0;
    position: absolute;
    background-color: $secondary;
    color: white;
    font-size: 10px;
    padding: 10px 10px;
}