.rides-card {
    padding-bottom: 16px !important;
}

.rides-card-margin {
    margin-bottom: 10px;
}

.rides-tabcontainer {
    flex-grow: 1;
    padding: 0
}

.rides-emptyText {
    width: 90%;
    margin: 0 auto;
    height: inherit;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.rides-appbar {
    z-index: 1000 !important;
}